// ###############################################################
// Main menu

$main-menu-width: 220px;
$main-menu-collapsed-width: 64px;
$main-menu-z-index: 11;
$main-menu-toggle-z-index: 12;

@media screen and (min-width: $screen-sm-min) {
  #wrapper-main-menu {
    display: flex;
    flex-direction: column;
    // Override the `height: 0` style that Bootstrap applies to collapsed navigation on mobile
    height: auto !important;

    // Make IE11 happy
    min-width: 0;
    flex-shrink: 0;
  }
}

.main-menu {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  z-index: $main-menu-z-index;
}

// ###############################################################
// Primary menu

.primary-menu {
  margin: 0;
  padding: 0;
  background-color: $navigation-background;
  color: white;
  width: 100%;
    ul > li.menu-bottom-fixed.box-shadow{
        border-top: none;
        box-shadow: 0 -3px 5px -3px rgba($navigation-text, 0.75);
    }
}

.primary-menu > ul {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  li {
    width: 100%;
  }
  > li > a,
  > li > button {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    text-decoration: none;
    background-color: $navigation-background;
    color: $navigation-text;
    width: 100%;
    border: none;
    padding: 20px 0;
    text-align: left;

    &:hover,
    &:focus{
      background-color: $navigation-hover;
      color: $navigation-text-hover;
      outline: none;
    }

    &.active {
      background-color: $navigation-active;
      color: $navigation-text-active;

      &:focus-visible {
         outline: 2px solid white;
         outline-offset: -4px;
      }
    }

    .primary-menu-button {
      &:focus,
      &.focus {
        border: none;
      }
    }

    .popover-wrap .popover-button {
      display: flex;
    }

    .icon {
      display: inline-block;
      width: $main-menu-collapsed-width + 1;
      text-align: center;
      font-size: 24px;
      flex-shrink: 0;
    }

    .text {
      flex-grow: 1;
      display: inline-block;
      padding-right: 15px;
      text-align: left;
    }

    .badge-alert {
      margin-right: 15px;
    }
  }
}

@media screen and (min-width: $screen-sm-min) {
  .primary-menu {
    width: auto;
    position: relative;
    flex-shrink: 0;
    overflow-x: hidden;
    width: $main-menu-collapsed-width;
    transition: width 0.2s ease-in;
  }

  .primary-menu > ul {
    width: $main-menu-width;
    li.menu-bottom-fixed {
      position: fixed;
      width: $main-menu-collapsed-width;
      bottom: 0;
      border-top: 1px solid rgba($navigation-text, 0.5);
      transition: width 0.2s ease-in;
      .text {
        display: none;
      }
    }
  }

  .primary-menu-open .primary-menu {
    width: $main-menu-width;
    transition: width 0.2s ease-out;
    .menu-bottom-fixed {
      width: $main-menu-width;
      .text {
        display: block;
      }
    }
  }
}

.primary-menu-submenu {
  padding: 0;
  margin: 20px 0;
  list-style: none;

  > li > a {
    color: $navigation-text;
    display: block;
    text-decoration: none;
    outline: 0;

    &:hover,
    &:focus {
      background-color: $navigation-hover;
      color: $navigation-text-hover;
    }

    .elements-inner-wrapper {
      display: block;
      padding: 7px 15px 7px 40px;
      margin: 0;
      text-align: left;
      border: none;
    }

    &.active {
      background-color: $navigation-active;
      color: $navigation-text-active;

      &:focus{
        .elements-inner-wrapper {
          outline: 1px solid $navigation-text-active
        }
      }
    }
  }
}

// ###############################################################
// Secondary menu

.secondary-menu {
  position: relative;
  margin: 0;
  padding: 0;
  flex-shrink: 0;
  overflow-x: hidden;
  background-color: #fff;

  /* Closed initially */
  width: 0;
  transition: width 0.2s ease-in;
}

.secondary-menu-open .secondary-menu {
  width: 100%;
  transition: width 0.2s ease-out;
  @include box-shadow(6px 0 7px 1px rgba(0, 0, 0, 0.12));
}

@media screen and (max-width: $screen-sm-min) {
  .secondary-menu {
    display: none;
  }

  .secondary-menu-open .secondary-menu {
    display: block;
  }

  .secondary-menu-open .primary-menu {
    display: none;
  }
}

@media screen and (min-width: $screen-sm-min) {
  .secondary-menu-open .secondary-menu,
  .primary-menu-open .primary-menu {
    width: $main-menu-width;
  }
}

.primary-menu-toggle {
  display: none;

  position: fixed;
  left: 48px;
  width: 30px;
  height: 30px;
  background: #fff;
  color: $link-color;
  transition: 0.2s ease-in;
  border-radius: 999px;
  border: none;
  z-index: $main-menu-toggle-z-index;
  @include box-shadow(0 0 5px 1px rgba(0, 0, 0, .2));
  bottom: 50px;

  span {
    position: absolute;
    top: 8px;
    left: 12px;
    transform: rotate(90deg);
  }
}

.primary-menu-open .primary-menu-toggle {
  transform: translate(156px, 0);
  transition: 0.2s ease-out;

  span {
    position: absolute;
    top: 8px;
    left: 4px;
    transform: rotate(-90deg);
  }
}

@media screen and (min-width: $screen-sm-min) {
  .primary-menu-toggle {
    display: block;
  }
}

.secondary-menu-items {
  h1 {
    margin: 0;
    padding: 30px;
  }
}

@media screen and (min-width: $screen-sm-min) {
  .secondary-menu-items {
    width: $main-menu-width;
  }
  .secondary-menu-open .secondary-menu-close {
    top: 10px !important;
    right: 10px !important;
  }
}

.secondary-menu-close {
  display: none;
  font-size: 110%;
  position: absolute;
  top: 42px;
  right: 32px;
  text-decoration: none;
  padding: 0;
  margin: 0;
  background-color: #fff;
  color: $font-primary-colour;
  border: none;
  font-weight: normal;

  &:hover,
  &:focus {
    outline: none;
  }

  &:focus, &:focus-visible, &:focus-within {
    outline: 2px solid #333;
    outline-offset: 2px;
  }
}

.secondary-menu-open .secondary-menu-close {
  display: inline-block;
  position: absolute;
  top: 20px;
  right: 32px;
}

.secondary-menu-submenu {
  padding-bottom: 30px;

  .toggle {
    display: block;
    font-weight: bold;
    background-color: #fff;
    color: $colour-tertiary;
    padding: 5px 30px;
    text-decoration: none;
    width: 100%;
    border: none;
    text-align: left;

    &:hover, &:focus, &:focus, &:focus-visible, &:focus-within {
      background-color: $navigation-hover;
      color: $navigation-text-hover;
      text-decoration: none;
      outline: 0;
    }
  }

  .submenu {
    display: block;
    padding: 0;
    margin: 0;
    list-style: none;

    > li > a {
      display: block;
      text-decoration: none;
      padding: 5px 30px;
      color: $secondary-submenu-navigation-text;

      &:hover,
      &:focus {
        background-color: $navigation-hover;
        color: $navigation-text-hover;
        outline: none;
      }

      &.active {
        background-color: $navigation-active;
        color: $navigation-text-active;

        &:focus, &:focus-visible, &:focus-within {
          outline: 2px solid white;
          outline-offset: -4px;
        }
      }
    }
  }
}
