@use "sass:math";

.button-pill {
  background: $colour-secondary;
  color: #fff;
  border-radius: $button-pill-height * 0.5;
  font-size: $button-pill-height * 0.5;
  height: $button-pill-height;
  line-height: $button-pill-height;
  overflow: hidden;
  padding: 0 math.div($button-pill-height, 2.25);
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
  span:first-child {
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    max-width: 300px;
    white-space: nowrap;
    display: inline;
  }
  span + span { margin-left: $button-pill-close-spacing; }
}

.button-pill-close {
  @include transition( all $transition-speed );
  @include position( relative , 0 -7px 0 0 );
  @include size( $button-pill-height - ($button-pill-close-spacing*2) );

  border-radius: 50%;
  cursor: pointer;
  float: right;
  font-size: $button-pill-close;
  line-height: $button-pill-height - ($button-pill-close-spacing*2);
  margin-top: $button-pill-close-spacing;
  text-align: center;

  a {
    font-size: 18px;
    color: #fff;
    font-weight: normal;
  }
}

.button-pill-close.standalone {
  a {
    color: $colour-link;

    &:hover {
      color: $colour-link-hover;
    }
    &:active {
      color: $colour-link-active;
    }
  }
}

@media screen and (min-width: $screen-lg-min) {
  .buttons-spacer {
    min-height: 50px;
  }
}

.form-button, .form-button:hover, .btn-link:hover, .btn-link:focus,
.btn-primary, .btn-secondary, .btn-tertiary,
.btn-primary:hover, .btn-secondary:hover, .btn-tertiary:hover
{
  outline: 2px solid transparent;
}

.btn-link:hover, .btn-link:focus {
  &.open-info-box {
    text-decoration: underline;
  }
}

.btn-link.open-info-box {
  font-weight: 300;
}

.btn-primary.btn-lg {
  letter-spacing: -0.02em;
}

.btn-tiny {
  font-size: 75%;
  padding: 2px 10px;
}
.btn-primary:focus-visible, .btn-primary:focus, .btn-primary:active,
a.btn-primary:focus-visible, a.btn-primary:focus, a.btn-primary:active {
  color: $colour-primary-hover-text;
  background-color: $colour-primary-hover;
  outline: 3px solid;
  outline-color:  $colour-primary-hover;
  outline-offset: 2px;
}

.btn-secondary:focus-visible, .btn-secondary:focus, .btn-secondary:active,
a.btn-secondary:focus-visible, a.btn-secondary:focus, a.btn-secondary:active {
  color: $colour-primary-hover-text;
  background-color: $colour-secondary-hover;
  outline: 3px solid;
  outline-color:  $colour-secondary-hover;
  outline-offset: 2px;
}

.btn-tertiary:focus-visible, .btn-tertiary:focus, .btn-tertiary:active,
a.btn-tertiary:focus-visible, a.btn-tertiary:focus, a.btn-tertiary:active {
  color: $colour-primary-hover-text;
  background-color: $colour-tertiary-hover;
  outline: 3px solid;
  outline-color:  $colour-tertiary-hover;
  outline-offset: 2px;
}

.action-button {
  display: inline-block;
  color: $colour-link;
  border: 1px solid $colour-link;
  border-radius: $default-radius;
  background: none;
  text-decoration: none;
  padding: 4px 11px;
  cursor: pointer;
  text-align: start;
  font-size: .8em;
  @include transition( background $transition-speed );

  &:hover, &:focus {
    text-decoration: none;
    color: #fff;
    border: 1px solid $colour-link;
    background-color: $colour-link;
  }

  &:focus, &:focus-visible, &:focus-within {
    background-color: $colour-link;
    outline: 3px solid $colour-link;
    outline-offset: 2px;
  }

  &:disabled, &[action-button-disabled], &.action-button-disabled {
    border-color: $colour-disabled;
    opacity: 1;
    color: $colour-disabled;
    cursor: not-allowed;

    &:hover, &:focus, &:active, &:focus-visible {
      background-color: transparent;
      cursor: not-allowed;
    }
  }

  &.lg-padding{
    padding: 7px 20px;
  }

  &.danger {
    color: $colour-danger;
  }
}

.action-list-horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 5px;
  padding-left: 0;

  li {
    list-style: none;
  }

  > li:not(:last-child) > a {
    margin-right: 6px;
  }

  & > li {
    margin-bottom: 6px;
  }
}


.icon-button {
  font-size: 20px;
  width: 30px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  text-decoration: none!important;
  cursor: pointer;
  margin: 0;
  border: none;
  border-radius: 50%;
  @include transition( background $transition-speed );
  color: $colour-link;

  &:hover {
    border-radius: 50%;
    background-color: $colour-link;

    .af-icons {
      color: #fff;
    }
  }

  &:focus, &:focus-visible, &:focus-within {
    border-radius: 50%;
    background-color: $colour-link;
    outline: 3px solid $colour-link;
    outline-offset: 2px;

    .af-icons {
      color: #fff;
    }
  }
}
